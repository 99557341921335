<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <BRow v-if="tank" class="mt-1">
      <BCol class="p-0">
        <BProgress :max="100" height="2rem" show-value class="border-danger">
          <BProgressBar class="text-dark font-weight-bold font-2xl"
                        :value="tank.volPercent"
                        :animated="true"
                        :variant="progressVariant(tank.volPercent)" />
        </BProgress>
      </BCol>
    </BRow>

    <BRow class="border-secondary mt-1">
      <BCol>
        <BTableSimple small class="text-nowrap text-right font-weight-bolder text-dark" v-if="tank">
          <BTr>
            <BTd class="font-xl font-weight-bolder text-center" colspan="2">
              <BBadge class="mr-1" variant="dark">{{ tank.name }}</BBadge>
              <BBadge class="mr-1" variant="primary">{{tank.tankCode}}</BBadge>
              <!--                        <BBadge variant="secondary">{{ tank.dfCode }}</BBadge>-->
              <BBadge v-if="pk" :variant="IoStsVariant[pk.sts.io]">{{ FuncCodeMap[pk.fcd] }}</BBadge>
            </BTd>
          </BTr>
          <BTr>
            <BTd>용도 </BTd>
            <BTd class="small">
              {{ tankUseMap[tank.tankUse] }}
            </BTd>
          </BTr>
          <BTr v-if="tank.tankShape==='4' && pk">
            <BTd>상태 정보</BTd>
            <BTd class="font-weight-bold font-lg">

              <BBadge :variant="IoStsVariant[pk.sts.io]">{{FuncCodeMap[pk.fcd]}}</BBadge>
              <div v-if="pk.fcd==='02'||pk.fcd==='03'||pk.fcd==='04'">
                <BBadge variant="info"> {{ EqCodeMap[pk.eqcd] }} </BBadge> <BBadge> {{ pk.eqid }} </BBadge>
              </div>
            </BTd>
          </BTr>
          <BTr v-if="tank.tankShape==='4'">
            <BTd>운행 정보</BTd>
            <BTd class="font-weight-bold">
              {{ opInfo.totalDistKm.toFixed(2) }} <BBadge>km</BBadge> {{ toHoursAndMinutes(Math.round(opInfo.totalOpMin)) }}
            </BTd>
          </BTr>

          <BTr v-if="tank.tankShape!=='4'">
            <BTd>탱크크기 <BBadge>mm</BBadge></BTd>
            <BTd class="small">
              H: {{comma(tank.tankHeight)}} W: {{comma(tank.tankWidth)}}
            </BTd>
          </BTr>
          <BTr>
            <BTd>저장능력 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
            <BTd>
              {{ volUnit==='g'?ltr2gal(tank.tankVolume): comma(tank.tankVolume)}}
            </BTd>
          </BTr>
          <BTr>
            <BTd>실용량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
            <BTd>
              {{ volUnit==='g'?ltr2gal(tank.tankRealVol): comma(tank.tankVolume)}}
            </BTd>
          </BTr>
          <BTr>
            <BTd>온도센서 수</BTd>
            <BTd>
              {{ tank.machine?tank.machine.numOfTemp:0 }} 개
            </BTd>
          </BTr>

        </BTableSimple>
        <BRow v-if="pk">
          <BCol class="text-left text-lg-center">
                        <span v-for="e of pk.events" :key="e.code">
                        <BBadge class="mr-1 font-xl" :variant="eventColorMap[e.code]">{{ eventCodeMap[e.code] }}</BBadge>
                        </span>
          </BCol>
        </BRow>
      </BCol>
      <BCol>
        <h5 v-if="!pk" class="text-center ml-2 mr-2 mt-lg-5 text-black-50 font-weight-bold text-justify"> 측정치 없음 </h5>
        <BTableSimple small class="text-dark text-nowrap text-right font-weight-bolder" v-if="pk && tank">
          <BTr>
            <BTd style="min-width:50px">유위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
            <BTd style="min-width:70px"><BBadge :variant="Colors.text.oil[status]">{{ trans(pk.ohr,'l',lenUnit) }}</BBadge></BTd>
          </BTr>
          <BTr>
            <BTd>수위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
            <BTd><BBadge :variant="Colors.text.wtr[status]">{{ trans(pk.whr,'l',lenUnit) }}</BBadge> </BTd>
          </BTr>
          <BTr>
            <BTd>측정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
            <BTd> <BBadge :variant="Colors.text.oil[status]">{{ trans(pk.ovm, 'v', volUnit) }}</BBadge> </BTd>
          </BTr>
          <BTr>
            <BTd>환산유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
            <BTd><BBadge>{{ trans(pk.ovc,'v', volUnit) }}</BBadge></BTd>
          </BTr>
          <BTr>
            <BTd>보정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
            <BTd><BBadge>{{ trans(pk.ovcr,'v', volUnit) }}</BBadge></BTd>
          </BTr>
          <BTr>
            <BTd>수분량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
            <BTd><BBadge> {{ trans(pk.wv, 'v', volUnit) }}</BBadge></BTd>
          </BTr>
          <BTr>
            <BTd>평균 <BBadge>{{ unitSign(tmpUnit) }}</BBadge></BTd>
            <BTd><BBadge> {{ trans(pk.avgTm, 't', tmpUnit) }} </BBadge></BTd>
          </BTr>

        </BTableSimple>



      </BCol>
    </BRow>

  </div>
</template>
<script>


// import moment from 'moment';
// import qs from "querystring";
import {
  apiCall,
  comma,
  ltr2gal,
  trans,
  unitSign,
} from "@/common/utils";
import {IoStsMap, IoStsVariant, Colors, EqCodeMap, eventColorMap, FuncCodeMap} from "@/common/constants";
// import TankLineChart from "@/views/components/charts/TankLineChart.vue";


export default {
  name: 'LayerPanel',


  components: {

  },

  props: {
    tank: {type: Object, default: null },
    pk: {type: Object, default: null },
  },
  data(){
    return {
      IoStsMap,
      IoStsVariant,
      eventColorMap,
      FuncCodeMap,

      opInfo: { totalDistKm: 0 , totalOpMin: 0},
      comma: comma,
      Colors: Colors,
      nozSizeMap: this.$store.state.codeMaps['NOZ_SIZE'],
      shapeMap: this.$store.state.codeMaps['TANK_SHAPE'],
      tankUseMap: this.$store.state.codeMaps['TANK_USE'],
      oilCodes: this.$store.state.codeMaps['OIL_CODE'],
      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],

      trans: trans,
      unitSign: unitSign,
      ltr2gal: ltr2gal,
      EqCodeMap,

      volUnit: 'g', // l <--> g
      lenUnit: 'i', // mm <--> i
      tmpUnit: 'f', // c <---> f
      status: 'off', // norm, warn, info, off
      volPercent: 0,

      chartKey: 0,


    }
  },
  async created(){
    console.log( '--------------- LayerPanel created()  --------------- tank = ', this.tank);
    if(this.tank && this.tank.tankShape==='4'){
      await this.getLastHist();

    }

  },
  async mounted () {
    console.log( '--------------- LayerPanel mounted() ---------------');
  },
  methods: {

    progressVariant(v){
      if(!v) return 'dark';

      if(v<10) return 'info';
      else if(v < 40) return 'primary';
      else if(v < 80) return 'success';
      else if(v < 95) return 'warning';
      else if(v >= 95) return 'danger';
      else return 'info';
    },

    /**
     *
     * @param tid
     * @returns {Promise<*>}
     */
    async getLastOperation(){
      try {
        const {result} = await apiCall('get', `/api/tank/lorry/operation/${this.tank.tid}`);
        this.opInfo = result;
      } catch (err){
        console.error(err);
      }
    },

    /**
     *
     * @param tid
     * @returns {Promise<*>}
     */
    async getLastHist(){
      try {
        const {result} = await apiCall('get', `/api/tank/lorry/hist/${this.tank.tid}`);
        this.opInfo = result;
      } catch (err){
        console.error(err);
      }
    },


  },

  watch: {
    /*tid: function(val){
      console.log( '--------------- child: watch( tankId ) LineByTank ---------------: '+val);
      this.renderLineChart();
    },
    toDts: function(val){
      console.log( '--------------- child: watch( searchDate ) LineByTank ---------------: '+val);
      this.renderLineChart();

    },*/
    // color: function(val){
    //   console.log( '--------------- child: watch( color ) LineByTank ---------------: '+val);
    // }
  }
}
</script>
