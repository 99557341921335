<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }"
       style="background-color: #000000">

    <!-- map container begin -->
    <BRow>
      <BCol ref="mapContainer" id="mapContainer" class="m-0 p-0" :style="mapStyle">

        <div  v-show="showLayer" class="map-overlay" @click.self="showLayer = false">
          <div class="map-layer-content"
               @mousedown="startDrag"
               :style="{ top: `${layerTop}px`, left: `${layerLeft}px` }">

            <BRow>
              <BCol ref="tankContainer"
                    :style="tankContainerStyle">
              </BCol>
              <BCol>

                <TankLineChart v-if="tank && graphData" style="min-height: 300px;"
                               :tank="tank"
                               :tankData="graphData"
                               :oilColor="oilColors[tank.oilCode]"
                               :key="chartKey"
                />

              </BCol>
            </BRow>

            <LayerPanel :tank="tank"
                        :pk="pk"
                        :key="barKey"
            />

            <BButton class="align-content-center small"
                     variant="info" size="sm" @click="closeLayer"
                     style="top:10px;right:10px;position:absolute">닫 기</BButton>


          </div>
        </div>


      </BCol>
    </BRow>



    <!-- map container end -->


    <CCard class="m-0 p-0 mt-1">
    <CCardHeader>
      <CIcon name="cil-map"/>
      3D 컨트롤
      <BButton size="sm" variant="info" @click="toggleSize">{{ toggleMapSize ? '작게보기' : '전체화면' }}</BButton>
      <BButton size="sm" variant="primary" @click="moveCamToStartPoint" class="ml-1" >초기위치로 이동</BButton>

      <div class="card-header-actions">
        <BBadge variant="info">selected</BBadge> <small class="text-muted"> {{ selectedPoint }} {{ camPoint }}</small>
        <BBadge variant="info">location</BBadge> <small class="text-muted"> {{ screenPoint }}</small>
      </div>
    </CCardHeader>
    <CCardBody>

      <CRow>
        <CCol v-if="tanks">
          <BButton size="sm" v-for="t in tanks"
                   :key="t.tid"
                   :variant="tankUseColor[t.tankUse]"
                   class="mr-1 mb-1"
                   v-show="t.display"
                   @click="popupLayer(t.tid);moveCamToTank(t.tid)">
            {{ t.name }} ({{t.tankCode}})
          </BButton>

        </CCol>
      </CRow>

      <CRow class="mt-2">
        <CCol v-if="tanks">
<!--          <BButton variant="danger" @click="moveObjectTest" class="mr-2 mb-1" >실시간 추적 가상카메라</BButton>-->
        </CCol>
      </CRow>


    </CCardBody>
  </CCard>


  </div>
</template>

<style>
.map-parent-div {
  position: relative; /* 부모 div를 상대 위치 기준으로 설정 */
  width: 80%;
  height: 400px;
  margin: 50px auto;
  border: 2px solid #2c3e50;
  text-align: center;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* 배경 클릭을 허용하지 않음 */

}

.map-layer-content {
  background: rgba(100, 255, 255, 0.20); /* 반투명 흰색 배경 */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  pointer-events: auto; /* 레이어 내용은 클릭 가능 */
  position: absolute;
  width: 900px; /* 너비 400px */
  height: 660px; /* 높이 500px */
  cursor: move; /* 드래그 커서 */
}

</style>

<script>



import store from "@/store";
import * as THREE from 'three';
import {CSG} from 'three-csg-ts';

import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {MapControls} from 'three/examples/jsm/controls/MapControls'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
// import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
// import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
// import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import {gsap} from 'gsap';
import {
  apiCall,
  getHexColor,
  getR,
  modalSuccess,
  modalWarn,
  sleep,
  speech,
  startFullScreen,
  cancelFullScreen,
  mergeBufferGeometries, ltr2gal
} from "@/common/utils";
import {eventColorMap, IoStsVariant} from "@/common/constants";
import LayerPanel from "@/views/components/LayerPanel.vue";
import TankLineChart from "@/views/components/charts/TankLineChart.vue";



export default {
  name: 'MapMonitor',
  components: {TankLineChart, LayerPanel},
  data() {
    return {
      ltr2gal,
      oilColors: this.$store.state.codeMaps['OIL_COLOR'],

      tank: null,
      message: '',
      showLayer: false,
      layerTop: 40, // 초기 top 위치
      layerLeft: 40, // 초기 left 위치
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,

      socket: null,

      tankContainer: null,
      tankContainerStyle: {
        width: "450px",
        height: "300px"
      },
      tankCamera: null,
      tankRenderer: null,
      tankScene: null,
      tankControls: null,
      layerTankObj: null,
      LayerVolObj: null,
      barKey: 0, // for progress bar refresh

      // layer vars
      pk: null,

      mapStyle: {height: '710px'},
      toggleMapSize: false,
      renderer: null,
      scene: null,
      camera: null,
      orbit: null,
      controls: null,
      raycaster: null,
      font: null,
      mouse: new THREE.Vector2(),

      tankObjs: {},
      labelObjs: {},
      volObjs: {},

      tankLorryGltf: null,
      fighterGltf: null,

      tankMap: {},
      tanks: [],

      wRatio: 0.0018,
      hRatio: 0.005,
      // yRatio: 0.01, // horizontal cylinder 에만 적용

      tankUseColor:{
        '1': 'success', // 대량저장
        '2': 'secondary', // Drain
        '3': 'danger', // truck
        '4': 'secondary', // 송유관
        '5': 'dark', // 드럼
        '6': 'warning', // 주유소
        '7': 'info', // 난방
        '': 'dark'
      },
      selectedPoint: null,
      camPoint: null,
      screenPoint: {x: 0, y: 0, z: 0},
      movePoints: [
        [127.501694, 36.715862],
        [127.506123, 36.714295],
        [127.511318, 36.719514],
        [127.512037, 36.720477],
        [127.513217, 36.721329],
        [127.514086, 36.720976],
        [127.506123, 36.714275],

      ],

      tankTypes: store.state.codeMaps['TANK_TYPE'],
      tankUses: store.state.codeMaps['TANK_USE'],
      tankShapes: store.state.codeMaps['TANK_SHAPE'],
      center: {lat: 36.707988, lng: 127.500557},
      markers: [],
      infoContent: '',
      infoLink: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      dmStyle: {
        "background": "rgba(0, 255, 255, 0.3)", /* 배경을 투명하게 설정 */
        "border": "none",
        "border-radius": "10px"
      },
      graphData: null,
      chartKey: 0,

    }
  },
  async created() {
    console.log("\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n---------created--------------------------\n")

    this.setSocketConnection();
    await sleep(1000);
    window.addEventListener("resize", this.onPageResizeHandler);

    await this.initLayer();

  },
  computed: {
    IoStsVariant() {
      return IoStsVariant
    },
    eventColorMap() {
      return eventColorMap
    }

  },

  async mounted() {
    this.renderer = new THREE.WebGLRenderer({antialias: true});
    this.container = this.$refs['mapContainer'];
    this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);

    this.container.appendChild(this.renderer.domElement);
    // this.renderer.setClearColor(0x202020);
    this.scene = new THREE.Scene();

    this.scene.background = new THREE.Color("skyblue");
    // this.renderer.domElement.addEventListener('click', this.onDocumentMouseClick, false);

    this.setCamera();
    this.loadMap();
    this.addLight();

    this.raycaster = new THREE.Raycaster();
    this.raycaster.near = 0.1;
    this.raycaster.far = 5000;


    /** helper for debug */
      // const gridHelper = new THREE.GridHelper(6700,100);
      // scene.add( gridHelper );

    const axesHelper = new THREE.AxesHelper(300);
    axesHelper.position.set(0, 0, 0);
    this.scene.add(axesHelper);

    await this.initLabels();
    await this.initTanks();
    this.renderer.setAnimationLoop(this.animate);
    this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);

    this.moveCamToStartPoint();
    this.closeLayer();
    window.addEventListener('click', this.onMouseClick, false);

    // tank container init

  },

  methods: {
    async getTankChart(){
      try{
        if(!this.tank) return;
        const {tid} = this.tank;
        const {result} = await apiCall('get', `/api/graph/tank/24?tid=${tid}`);
        this.graphData = result.tankHist[tid];
        console.debug( this.graphData);
        this.chartKey++;
      }catch(err){
        console.error(err);
      }
    },

    async initLayer() {

      const tankContainer = this.$refs['tankContainer'];
      const tankRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      tankRenderer.setSize(450, 300);

      tankContainer.appendChild(tankRenderer.domElement);
      const tankScene = new THREE.Scene();
      const tankCamera = new THREE.PerspectiveCamera(
        75, 450/300, 0.1, 1000);

      const light = new THREE.DirectionalLight(0xffffff, 1);
      // const light = new THREE.AmbientLight(0xFFFFFF);
      light.position.set(5, 100, 7.5);
      tankScene.add(light);

      const axesHelper = new THREE.AxesHelper(10);
      axesHelper.position.set(0,0,0);
      tankScene.add( axesHelper );


      tankCamera.position.set(500,10, 500);

      // OrbitControls
      const controls = new OrbitControls( tankCamera, tankRenderer.domElement);
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation is enabled
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;
      controls.maxPolarAngle = Math.PI / 2;
      tankCamera.lookAt(0, 0, 0);

      this.tankScene = tankScene;
      this.tankRenderer = tankRenderer;
      this.tankCamera = tankCamera;

      // let angle = 0;
      // const radius = 90;
      function tankAnimate() {
        // angle += 0.02;
        // tankCamera.position.x = radius * Math.sin(angle);
        // tankCamera.position.z = radius * Math.cos(angle);
        // tankCamera.lookAt(0,0,0);

        requestAnimationFrame(tankAnimate);
        controls.update();

        tankRenderer.render(tankScene, tankCamera);
      }

      tankAnimate();

    },

    /**
     * tank container draw tank at tank container
     * @param t tank
     * @param pk
     * @returns {Promise<void>}
     */
    getLayerScale(){},

    clearObject(object, scene) {
        if (object.geometry) {
          object.geometry.dispose();
        }

        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach(material => material.dispose());
          } else {
            object.material.dispose();
          }
        }

        scene.remove(object);
      },


    async drawTankOnLayer(t, pk=null){

      const {tid} = t;
      console.warn( "------------draw-tank-on-layer------------");
      console.warn( "tank  shape , width, height =>", t.tankShape, t.tankWidth, t.tankHeight );

      if(this.layerTankObj) {
        console.warn( "------------draw-tank-on-layer --- layerTankObj exists------------" );
        this.tankScene.remove(this.layerTankObj);
        this.layerTankObj = null;
      }

      const camera = this.tankCamera;
      const tankObj = this.tankObjs[tid].mes.clone();

      tankObj.position.set(0, 0 ,0);
      tankObj.updateMatrix();
      this.tankScene.add( tankObj );
      this.drawVolumeOnLayer(t, pk);

      this.layerTankObj = tankObj;
      console.warn( "------------drawTankOnLayer --- add tank scene done!");

      let scale = this.getScale(t);
      // 스케일 확대
      gsap.to(tankObj.scale, {
        duration: 2,
        repeat: 0,
        x: scale,
        y: scale,
        z: scale,
        yoyo: false,
        ease: "sine.inOut",
      });

      // 카메라 회전
      let angle = 0;
      const radius = 90;
      gsap.to(camera.position, {
        duration: 30,
        repeat: -1, // 무한 반복
        yoyo: false, // 역방향 애니메이션
        onUpdate: ()=> {
          angle += 0.02;
          camera.position.x = radius * Math.sin(angle);
          camera.position.z = radius * Math.cos(angle);
        },
      });

    },

    getScale(t){
      let scale = 1;
      // let tankY = 0;
      // if( t.tankShape==='2') tankY = h * this.hRatio * 0.5;
      const h = t.tankHeight
      if( h < 3000 ) scale = 3;
      else if ( h < 5000 ) scale = 2;
      else scale = 1.1;

      if(t.tankShape==='4') { // 탱크로리
        scale = 0.14; // 0.09 --> 0.14 = 155%
      }
      return scale

    },

    drawVolumeOnLayer(t, pk=null){
      console.log(  '------------draw-volume-on-layer-------------' );
      const {tid} = t;

      let scale = this.getScale(t);

      if( this.layerVolObj ){
        console.log(  '------------draw-volume-on-layer------ delete layerVolObj-------------' );
        this.tankScene.remove(this.layerVolObj.oil);
        this.tankScene.remove(this.layerVolObj.wtr);
      }

      if(pk){
        this.pk = pk;
        this.tank.volPercent = pk.tvp;
        this.barKey++;
      }else{
        return;
      }

      const volObj = this.volObjs[tid];
      if( volObj && volObj.mes && volObj.mes.oil ){
        console.log( '------------draw-volume-on-layer---volume exist ')
      }else{
        console.log( '------------draw-volume-on-layer---volume not exist ---- skip')
        return false;
      }

      const oilVolObj = volObj.mes.oil.clone();
      const wtrVolObj = volObj.mes.wtr.clone();

      const {oilH} = volObj;
      const {wtrH} = volObj;

      let oilY, wtrY;
      let th = t.tankHeight * this.hRatio;
      wtrY = (-th*0.5) + ( wtrH * 0.5 )
      oilY = (-th*0.5) + ( oilH * 0.5 ) + wtrH + 1;

      if(t.tankShape==='2'){ // Horizontal 실린더 인경우
        // wtrY = ( -th * 0.5 ) + ( wtrH * 0.5 );
        // oilY = ( -th * 0.5 ) + ( oilH * 0.5 );
        wtrY = -volObj.tankR * 0.5 + 1;
        oilY = -volObj.tankR * 0.5 + 1;

      }

      oilVolObj.position.set(0,oilY,0);
      wtrVolObj.position.set(0,wtrY,0);
      // oilVolObj.updateMatrix();
      // wtrVolObj.updateMatrix();

      gsap.to(oilVolObj.scale, {
        duration: 2.5,
        repeat: 0,
        x: scale,
        y: scale,
        z: scale,
        yoyo: false,
        ease: "sine.inOut",
      });

      gsap.to(wtrVolObj.scale, {
        duration: 2.5,
        repeat: 0,
        x: scale,
        y: scale,
        z: scale,
        yoyo: false,
        ease: "sine.inOut",
      });

      this.layerVolObj = {
        oil: oilVolObj,
        wtr: wtrVolObj
      }

      this.tankScene.add(oilVolObj);
      this.tankScene.add(wtrVolObj);

    },

    async toggleSize() {
      this.toggleMapSize = !this.toggleMapSize;
      if (this.toggleMapSize === true) {
        startFullScreen();
        await sleep(500);
        this.$store.state.sidebarMinimize = true;
        this.mapStyle = {height: `${window.innerHeight - 350}px`}
        // document.getElementById('mapContainer').style.height= '1000px';
        // this.$refs.mapContainer[0].style = { height: '1000px' }
      } else {
        cancelFullScreen();
        await sleep(500);
        this.$store.state.sidebarMinimize = false;
        this.mapStyle = {height: '710px'}
        // this.$refs['mapContainer'].style.height = '800px';
      }

      await sleep(500);

      this.onPageResizeHandler();

    },

    animate() {
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.container.offsetWidth / this.container.offsetHeight,
        0.1,
        18000
      );

      // this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      this.controls = new MapControls(this.camera, this.renderer.domElement)
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;
      this.controls.enableZoom = true;


      // this.camera.position.set( 113,800,150 ); // x, y(높이), z
      this.camera.position.set(0, 100, 0); // x, y(높이), z
      this.camera.lookAt(0, 0, 0);

      // this.orbit.update();
    },
    async popupLayer( tid ){
      this.tank = this.tankMap[tid];
      this.tank['volPercent'] = 0;

      this.volUnit = this.tank.unitOfVol?this.tank.unitOfVol:'l';
      this.lenUnit = this.tank.unitOfLen?this.tank.unitOfLen:'mm';
      this.tmpUnit = this.tank.unitOfTmp?this.tank.unitOfTmp:'c';

      let pk;
      try {
        const rs = await apiCall('get', `/api/inventory/latest/${tid}`);
        if(rs.code===200 && rs.result) {
          pk = rs.result;
          this.pk = pk;
          this.tank.volPercent = pk.tvp;
          this.layerKey++;
        }
      } catch (err){
        console.error(err);
      }

      await this.drawTankOnLayer(this.tank, pk);
      this.showLayer = true;

      await this.getTankChart();
    },

    closeLayer(){
      this.showLayer = false;
      this.tank = null;
      this.selectedPoint = null;
      this.camPoint = null;

      if(this.layerTankObj) {
        this.tankScene.remove(this.layerTankObj);
        this.layerTankObj = null;
      }
    },

    async moveObjectTest() {
      for (const coordinates of this.movePoints) {
        this.moveTankLorry('2DA6', coordinates);
        await sleep(3000);
      }
    },

    async moveCamToTank(tid) {
      const p = this.tankMap[tid].position;
      if (!p || p.x === 0) {
        alert('[위치정보 없음] 이동할 수 없습니다.');
        return;
      }
      let cmp; // camera position

      this.selectedPoint = {x:p.x, y:p.y, z:p.z};
      if ( p.cx && p.cz ) {
        cmp = {
          x: p.cx,
          y: p.cy,
          z: p.cz
        }
      } else {
        cmp = {
          x: (p.x < 0) ? p.x + p.ax - 200 : p.x + p.ax + 200,
          y: p.y + p.ay + 100,
          z: (p.z < 0) ? p.z + p.az - 250 : p.z + p.az + 250
        }
        // cmp = {
        //   x: p.x + p.ax - 200,
        //   y: p.y + p.ay + 100,
        //   z: p.z + p.az - 250
        // }
      }

      this.camPoint = {x:cmp.x, y:cmp.y, z:cmp.z};
      console.debug('moveCamToTank--->', tid, JSON.stringify(p), 'fixed moveTo=', JSON.stringify(cmp));

      const label = this.labelObjs[tid];
      const color = this.tankMap[tid].color;
      const camera = this.camera;


      gsap.to(camera.position, {
        duration: 0.7,
        repeat: 0,
        x: cmp.x,
        y: cmp.y,
        z: cmp.z,
        onUpdate: () => {
          camera.lookAt(p.x, p.y, p.z);
        },
        onComplete: () => {
        }
      });
      await sleep(1000);

      this.blinkObject(label, getHexColor(color));

      /*
      gsap.to(camera.position, {
        duration: 2,
        repeat: 0,
        x: p.cx + -40 * Math.sin(Math.PI),
        y: moveTo.y * Math.sin(Math.PI),
        z: p.cz + -40 * Math.cos(Math.PI),
        ease: "power1.inOut",
        modifiers: {
          x: (x) => p.cx + -40 * Math.sin(performance.now() / 1000),
          y: (y) => moveTo.y + -40 * Math.cos(performance.now() / 1000),
          z: (z) => p.cz + -40 * Math.cos(performance.now() / 1000)
        },
        onUpdate: () => {
          camera.lookAt(p.x, p.y, p.z);
        },
        onComplete: () => {
        }
      });
      */
      /*
            gsap.to( camera.position, {
              x: moveTo.x,
              y: moveTo.y,
              z: moveTo.z,
              duration: 2, // 애니메이션 시간 (초)
              modifiers: {
                // x: (x) => x * Math.sin(performance.now() / 1000),
                // z: (z) => z * Math.cos(performance.now() / 1000)
              },
              onUpdate: () => {
                //camera.lookAt( new THREE.Vector3(p.x+p.ax, p.y, p.z + p.az) ); // 카메라가 항상 중앙을 바라보도록 하려면 (0,0,0)
                camera.lookAt( tankMesh.position );
                // this.camera.lookAt( new THREE.Vector3( 0, 0, 0 )); // 카메라가 항상 중앙을 바라보도록 하려면 (0,0,0)
              },
              onComplete: ()=>{
              }
            });
        */
      // console.debug('moveCamToTank() --->', moveTo );

    },

    async getLatestPacket(tid){
      try {
        const {result} = await apiCall('get', `/api/inventory/latest/${tid}`);
        return result;
      } catch (err){
        console.error(err);
      }

    },

    moveCamToStartPoint() {
      this.showLayer = false;
      this.tank = null;
      this.selectedPoint = null;
      this.camPoint = null;

      if(this.layerTankObj) {
        this.tankScene.remove(this.layerTankObj);
        this.layerTankObj = null;
      }

      gsap.to(this.camera.position, {
        x: -3800, // 좌측
        y: 1800, // 높이
        z: 5600, // 아래
        duration: 3, // 애니메이션 시간 (초)
        onUpdate: () => {
          this.camera.lookAt(0, 0, 0); // 카메라가 항상 중앙을 바라보도록
        }
      });
    },

    async blinkObject(obj, color, cnt = 5) {
      for (let i = 0; i < cnt; i++) {
        obj.material.color.set(0x000000);
        await sleep(300);
        obj.material.color.set(color);
        await sleep(300);
      }
    },

    loadMap() {
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load('/map-data/circle-map-v2.jpg');
      // const geometry = new THREE.PlaneGeometry(10120, 10120); // Adjust the size as needed
      const geometry = new THREE.CircleGeometry(10120 / 2, 180); // Adjust the size as needed
      // const backgroundColor = new THREE.Color(0xFF0000);

      /*
            const material = new THREE.MeshBasicMaterial({
                // color: new THREE.Color(0xFF0000),
                map: texture,
                alphaMap: texture,
                transparent: false,
                side: THREE.DoubleSide
              }
            );
      */
      const material = new THREE.ShaderMaterial({
        uniforms: {
          texture1: {type: 't', value: texture},
          darkness: {type: 'f', value: 1.1} // 어둡게 만들기 위한 값 (0.0 ~ 1.0)
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform sampler2D texture1;
            uniform float darkness;
            varying vec2 vUv;
            void main() {
                vec4 color = texture2D(texture1, vUv);
                color.rgb *= darkness; // 텍스처의 색상을 어둡게 만듭니다.
                gl_FragColor = color;
            }
        `,
        transparent: true
      });

      const mapPlane = new THREE.Mesh(geometry, material);
      // this.mapPlane.rotation.z = Math.PI / 2;
      mapPlane.rotation.x = Math.PI / -2;
      // this.mapPlane.position.y = -5;
      this.scene.add(mapPlane);
    },

    addLight() {
      // const ambientLight = new THREE.AmbientLight(0x404040);
      const ambientLight = new THREE.DirectionalLight(0xffffff, 1);

      ambientLight.position.set(1000, 600, -2000);
      this.scene.add(ambientLight);

      const light = new THREE.DirectionalLight(0xffffff, 0.8);
      // light.castShadow = true; // true 이면 광원이 그림자를 생성합니다. 기본값은 false 입니다.

      light.position.set(-1000, 800, 5);
      this.scene.add(light);
    },


    async initLabels() {
      return new Promise((resolve, reject) => {
        const fontLoader = new FontLoader();
        // let font = this.font;
        // let drawLabel = this.drawLabel;
        fontLoader.load('/fonts/NanumGothic_Regular.json', (rs, err) => {
          this.font = rs;
          this.drawLabel('X', {x: 300, y: 0, z: 0, ax: 0, ay: 0, az: 0});
          this.drawLabel('Y', {x: 0, y: 300, z: 0, ax: 0, ay: 0, az: 0});
          this.drawLabel('Z', {x: 0, y: 0, z: 300, ax: 0, ay: 0, az: 0});
          console.log('################## font loaded #####################')
          if (err) reject(false);
          resolve(true);
        })
      })
    },

    /**
     * 탱크 초기화 - 객체들 모두 그린다.
     * @returns {Promise<void>}
     */
    async initTanks() {
      try {

        // const r = await apiCall('get', `/api/tank?onlyTank=1`);
        const r = await apiCall('get', `/api/tank/latest`);

        this.tanks = r.result.tanks;
        const pkMap = r.result.pkMap;

        for (const t of this.tanks) {
          const {tid} = t;
          this.tankMap[t.tid] = t;
          if(!t.display) continue;
          // if(t.location.coordinates[0]===0) {
          //   console.debug('location field not found draw skip---', t.name);
          //   continue;
          // }

          const pos = this.convertTankPosition(tid);

          if (t.position.x === 0 && t.position.z === 0) continue;

          const {tankShape} = t;
          // pos['cx'] = (pos.x<0)? pos.x+pos.ax - 200 : pos.x + pos.ax + 200;
          // pos['cy'] = pos.y + pos.ay + 100;
          // pos['cz'] = (pos.z<0)? pos.z+pos.az - 250 : pos.z + pos.az + 250;

          // const updRs = await apiCall( 'put', `/api/tank/position-xyz/${t._id}`, pos);
          // const dataRs = await apiCall('get', `/api/inventory/latest/${tid}`);
          // const pk = dataRs.result;

          const pk = pkMap[tid];

          if (tankShape==='1' || tankShape==='3') { // Exclusive, ConRoof
            console.debug('[initTanks] ******* drawStorageTank()------------>', t.name, tid, pos, t.tankWidth, t.tankHeight, t.color);
            this.drawStorageTank(this.scene, t, this.wRatio, this.hRatio);
            if (pk) await this.initStorageVolume(tid, pk);

          }  else if (tankShape==='2') { //horizontal cylinder
            console.debug('[initTanks] ######## drawCylinderTank()------->', t.name, tid, pos, t.tankWidth, t.tankHeight, t.color);
            this.drawCylinderTank(this.scene, t);
            if (pk) await this.initCylinderVolume(tid, pk);
            else console.debug("--->skip init--volume --- packet not found ---- ", t.name);

          } else {
            console.warn('[initTanks] ######## draw nothing ------->', t.name, tid, pos, t.tankWidth, t.tankHeight, t.color);
            return;
          }

          // this.drawLabel( t.name, pos, 0x00FF00, 20, 5  );

          this.drawLabel(t.name, t.position, t.color, 10, 3, tid);


        }
        // this.$refs['excelGrid'].clearFilter();
      } catch (err) {
        console.error('[initTanks] --- error ---', err);
      }
    },

    drawStorageVolumeByPacket(tid, pk) {
      console.warn('\n\n\n\n\n--------------draw-storage-volume-by-packet--------------');
      const t = this.tankMap[tid];
      const pos = t.position;
      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;


      let oilH = (pk.ohr - pk.whr) * this.hRatio;
      let wtrH = pk.whr * this.hRatio;
      let wtrY = (wtrH * 0.5) + pos.y;
      let oilY = (oilH * 0.5) + wtrH + py;
      const volObj = this.volObjs[tid]

      if (!volObj) {
        this.initStorageVolume(tid, pk);
        return;
      }

      const oilMes = volObj.mes.oil;
      const wtrMes = volObj.mes.wtr;
      const matOil = volObj.mat.oil;
      const matWtr = volObj.mat.wtr;

      // const oldOilY = volObj.oilY;
      // const oldWtrY = volObj.wtrY;
      // const oldOilH = volObj.oilH;
      // const oldWtrH = volObj.wtrH;
      const tankR = volObj.tankR;
      // const oilColor = getHexColor(this.oilColors[t.oilCode]);

      this.scene.remove(oilMes);
      this.scene.remove(wtrMes);

      const geoOil = new THREE.CylinderGeometry(tankR, tankR, oilH, 36, 1);
      const geoWtr = new THREE.CylinderGeometry(tankR, tankR, wtrH, 36, 1);
      const mesOil = new THREE.Mesh(geoOil, matOil);
      const mesWtr = new THREE.Mesh(geoWtr, matWtr);

      mesOil.position.set(px, oilY, pz);
      mesWtr.position.set(px, wtrY, pz);
      mesOil.updateMatrix();
      mesWtr.updateMatrix();

      this.scene.add(mesOil);
      this.scene.add(mesWtr);

      this.volObjs[tid] = {
        geo: {
          oil: geoOil,
          wtr: geoWtr,
        },
        mat: {
          oil: matOil,
          wtr: matWtr
        },
        mes: {
          oil: mesOil,
          wtr: mesWtr,
        },
        oilY,
        wtrY,
        oilH,
        wtrH,
        tankR
      };

      if( this.tank?.tid===tid){
        this.drawVolumeOnLayer(t, pk);
      }

      gsap.to(mesOil.scale, {
        duration: 0.5,
        x: pk.isEvt ? 6 : 3, // 이벤트 있는경우 6배로 커짐
        z: pk.isEvt ? 6 : 3, // 이벤트 있는경우 6배로 커짐
        yoyo: true,
        repeat: pk.isEvt ? 9 : 5,
        ease: "sine.inOut",
        onComplete: () => {
          mesOil.scale.set(1, 1, 1);
        }
      });

      // 이벤트 있는경우
      if (pk.isEvt) {
        // const color = matOil.color;
        gsap.to(matOil.color, {
          duration: 0.5,
          r: 1,
          g: 0,
          b: 0,
          yoyo: true,
          repeat: 9,
          ease: "sine.inOut",
          onComplete: () => {
            // 애니메이션이 끝난 후 색상을 원래대로 복원
            // matOil.color = oilColor;
          }
        });
      }
    },

    drawCylinderVolumeByPacket(tid, pk) {
      console.warn('\n\n\n\n\n--------------draw-cylinder-volume-by-packet--------------');
      const t = this.tankMap[tid];
      const pos = t.position;
      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;

      const volObj = this.volObjs[tid]

      let oilH = pk.oh * this.hRatio;
      let wtrH = pk.wh * this.hRatio;

      const tankR = (t.tankHeight * 0.5 * this.hRatio);
      const tankH = t.tankWidth * this.hRatio;

      // const oilColor = getHexColor(this.oilColors[t.oilCode]);

      console.warn('{{{ draw-cylinder-volume - packet }}} --- ', t.name, tankR, tankH, this.oilColors[t.oilCode]);

      if (volObj) {
        this.scene.remove(volObj.mes.oil);
        this.scene.remove(volObj.mes.wtr);

        volObj.geo.oil.dispose();
        volObj.geo.wtr.dispose();
        volObj.geo.box.dispose();
        volObj.mat.oil.dispose();
        volObj.mat.wtr.dispose();
        volObj.mat.box.dispose();
      }

      const oilColor = getHexColor(this.oilColors[t.oilCode]);
      const geoOil = new THREE.CylinderGeometry(tankR, tankR, tankH, 36, 1);
      const matOil = new THREE.MeshLambertMaterial({
        color: oilColor,
        side: THREE.DoubleSide,
        opacity: 0.5,
        transparent: true
      });
      const matWtr = new THREE.MeshLambertMaterial({color: 0x0000DD, opacity: 0.6, transparent: true});

      const geoBox = new THREE.BoxGeometry(tankR * 2, tankH, tankR * 2, 1, 1, 1);
      const matBox = new THREE.MeshNormalMaterial();

      const mesOil = new THREE.Mesh(geoOil, matOil);
      const mesWtr = new THREE.Mesh(geoOil, matWtr);
      const mesBox = new THREE.Mesh(geoBox, matBox);

      mesOil.position.set(0, 0, 0);
      mesWtr.position.set(0, 0, 0);
      mesBox.position.set(oilH, 0, 0);

      mesOil.updateMatrix();
      mesWtr.updateMatrix();
      mesBox.updateMatrix();

      const csgOil = CSG.fromMesh(mesOil);
      const csgBox = CSG.fromMesh(mesBox);
      const csgPreSub = csgOil.subtract(csgBox);


      mesBox.position.set(wtrH, 0, 0);
      mesBox.updateMatrix();
      const csgWtr = CSG.fromMesh(mesWtr);
      const csgWtrBox = CSG.fromMesh(mesBox);
      const csgWtrSub = csgWtr.subtract(csgWtrBox);
      const csgOilSub = csgPreSub.subtract(csgWtrSub);

      const oilVol = CSG.toMesh(csgOilSub, mesOil.matrix, mesOil.material);
      const wtrVol = CSG.toMesh(csgWtrSub, mesWtr.matrix, mesWtr.material);

      oilVol.rotateY(Math.PI / 4.7);
      oilVol.rotateZ(Math.PI / 2);
      wtrVol.rotateY(Math.PI / 4.7);
      wtrVol.rotateZ(Math.PI / 2);

      const wtrY = tankR;
      const oilY = tankR;

      // console.warn( `initCylinderVol2 --- ${tankH}(tankH) - ${oilH}(oilH) = `, tankH - oilH );
      oilVol.position.set(px, oilY+py, pz);
      wtrVol.position.set(px, wtrY+py, pz);

      // mesVol.updateMatrix();
      // this.scene.add( mesOil );
      // this.scene.add( mesBox );

      this.volObjs[tid] = {
        geo: {
          oil: geoOil,
          wtr: geoOil, // wtrGeo = oilGeo
          box: geoBox
        },
        mat: {
          oil: matOil,
          wtr: matWtr,
          box: matBox
        },
        mes: {
          oil: oilVol,
          wtr: wtrVol,
          box: mesBox
        },
        oilY,
        wtrY,
        oilH,
        wtrH,
        tankR
      };

      this.scene.add(oilVol);
      this.scene.add(wtrVol);

      if( this.tank?.tid===tid){
        this.drawVolumeOnLayer(t, pk);
      }

      const tankObj = this.tankObjs[tid];
      // const scaleOrgin = tankObj.mes.scale

      gsap.to(tankObj.mes.scale, {
        duration: 0.5,
        repeat: pk.isEvt ? 11 : 5,
        x: pk.isEvt ? 9 : 3, // 이벤트 있는경우 6배로 커짐
        y: pk.isEvt ? 9 : 3, // 이벤트 있는경우 6배로 커짐
        z: pk.isEvt ? 9 : 3, // 이벤트 있는경우 6배로 커짐
        yoyo: true,
        ease: "sine.inOut",
        onUpdate: () => {
          // mesOil.position.y = (mesOil.scale.y - 1) / 2;
        }, onComplete: () => {
          tankObj.mes.scale.set(1, 1, 1);
        }
      });

      // 이벤트 있는경우
      if (pk.isEvt) {
        console.warn('isEvt --->', pk.isEvt);
        // const color = matOil.color;
        gsap.to(tankObj.mat.color, {
          duration: 0.5,
          repeat: 11,
          r: 1,
          g: 0,
          b: 0,
          yoyo: true,
          ease: "sine.inOut",
          onComplete: () => {
            // 애니메이션이 끝난 후 색상을 원래대로 복원
            // matOil.color = color;
          }
        });
      }

    },

    handleDrawVolume(pk) {
      console.warn( '----------handle-draw-volume--------')
      const {tid} = pk;
      const t = this.tankMap[pk.tid];
      if(!t) return;

      const {tankShape} = t;

      switch(tankShape){
        case '1': // Exclusive
        case '3': // cone-roof
          this.drawStorageVolumeByPacket(tid, pk);
          break;
        case '2': // cylinder - horizontal
          this.drawCylinderVolumeByPacket(tid, pk);
          break;
        default:
          console.warn('unsupported shape--->', tankShape)
      }
    },


    /**
     * 탱크내 유량재고 그리기
     * @param t
     * @param pk
     * @returns {Promise<void>}
     */
    async initStorageVolume(tid, pk) {
      const t = this.tankMap[tid];
      const pos = t.position;

      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;


      let oilH = (pk.ohr - pk.whr) * this.hRatio;
      let wtrH = pk.whr * this.hRatio;
      let wtrY = (wtrH * 0.5) + py;
      let oilY = (oilH * 0.5) + wtrH + py;

      console.warn(tid, t.name, "oilY =", oilY, "wtrY =", wtrY);

      const tankR = t.tankWidth * this.wRatio - 1;
      const oilColor = getHexColor(this.oilColors[t.oilCode]);
      console.debug('initStorageVolume --- oilColor', this.oilColors[t.oilCode]);

      const geoOil = new THREE.CylinderGeometry(tankR, tankR, oilH, 36, 1);
      const geoWtr = new THREE.CylinderGeometry(tankR, tankR, wtrH, 36, 1);
      const matOil = new THREE.MeshLambertMaterial({
        color: oilColor,
        side: THREE.DoubleSide,
        opacity: 0.4,
        transparent: true
      });
      const matWtr = new THREE.MeshLambertMaterial({color: 0x0000DD, opacity: 0.4, transparent: true});
      const mesOil = new THREE.Mesh(geoOil, matOil);
      const mesWtr = new THREE.Mesh(geoWtr, matWtr);

      mesOil.position.set(px, oilY, pz);
      mesWtr.position.set(px, wtrY, pz);

      mesOil.updateMatrix();
      mesWtr.updateMatrix();

      mesOil.name = 'oil_'+tid;
      mesWtr.name = 'wtr_'+tid;

      this.scene.add(mesOil);
      this.scene.add(mesWtr);

      this.volObjs[tid] = {
        geo: {
          oil: geoOil,
          wtr: geoWtr,
        },
        mat: {
          oil: matOil,
          wtr: matWtr
        },
        mes: {
          oil: mesOil,
          wtr: mesWtr,
        },
        oilY,
        wtrY,
        oilH,
        wtrH,
        tankR
      }
    },

    initCylinderVolume(tid, pk) {
      const t = this.tankMap[tid];
      const pos = t.position;
      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;

      let oilH = pk.oh * this.hRatio;
      let wtrH = pk.wh * this.hRatio;

      const tankR = (t.tankHeight / 2 * this.hRatio);
      const tankW = t.tankWidth * this.hRatio;

      const oilColor = getHexColor(this.oilColors[t.oilCode]);

      console.warn('[[[[ init-cylinder-volume ]]]] ---', t.name, tankR, tankW, this.oilColors[t.oilCode]);

      const geoOil = new THREE.CylinderGeometry(tankR, tankR, tankW, 36, 1);
      const matOil = new THREE.MeshLambertMaterial({
        color: oilColor,
        side: THREE.DoubleSide,
        opacity: 0.5,
        transparent: true
      });
      const matWtr = new THREE.MeshLambertMaterial({color: 0x0000DD, opacity: 0.6, transparent: true});

      const geoBox = new THREE.BoxGeometry(tankR * 2, tankW, tankR * 2, 1, 1, 1);
      const matBox = new THREE.MeshNormalMaterial();

      const mesOil = new THREE.Mesh(geoOil, matOil);
      const mesWtr = new THREE.Mesh(geoOil, matWtr);
      const mesBox = new THREE.Mesh(geoBox, matBox);

      mesOil.position.set(0, 0, 0);
      mesWtr.position.set(0, 0, 0);
      mesBox.position.set(oilH, 0, 0);

      mesOil.updateMatrix();
      mesWtr.updateMatrix();
      mesBox.updateMatrix();

      const csgOil = CSG.fromMesh(mesOil);
      const csgBox = CSG.fromMesh(mesBox);
      const csgPreSub = csgOil.subtract(csgBox);

      mesBox.position.set(wtrH, 0, 0);
      mesBox.updateMatrix();
      const csgWtr = CSG.fromMesh(mesWtr);
      const csgWtrBox = CSG.fromMesh(mesBox);
      const csgWtrSub = csgWtr.subtract(csgWtrBox);
      const csgOilSub = csgPreSub.subtract(csgWtrSub);

      const oilVol = CSG.toMesh(csgOilSub, mesOil.matrix, mesOil.material);
      const wtrVol = CSG.toMesh(csgWtrSub, mesWtr.matrix, mesWtr.material);

      oilVol.rotateY(Math.PI / 4.7);
      oilVol.rotateZ(Math.PI / 2);
      wtrVol.rotateY(Math.PI / 4.7);
      wtrVol.rotateZ(Math.PI / 2);


      const wtrY = tankR;
      const oilY = tankR;

      // console.warn( `initCylinderVol2 --- ${tankH}(tankH) - ${oilH}(oilH) = `, tankH - oilH );
      oilVol.position.set(px, oilY+py, pz);
      wtrVol.position.set(px, wtrY+py, pz);

      oilVol.updateMatrix();
      wtrVol.updateMatrix();

      oilVol.name = 'oil_'+tid;
      wtrVol.name = 'wtr_'+tid;

      this.scene.add(oilVol);
      this.scene.add(wtrVol);

      // mesVol.updateMatrix();
      // this.scene.add( mesOil );
      // this.scene.add( mesBox );

      this.volObjs[tid] = {
        geo: {
          oil: geoOil,
          wtr: geoOil, // wtrGeo = oilGeo
          box: geoBox
        },
        mat: {
          oil: matOil,
          wtr: matWtr,
          box: matBox
        },
        mes: {
          oil: oilVol,
          wtr: wtrVol,
          box: mesBox
        },
        oilY,
        wtrY,
        oilH,
        wtrH,
        tankR
      };
    },

    /**
     * 대량저장탱크 그리기
     * @param scene
     * @param t
     * @param wRatio 가로비율(*x)
     * @param hRatio 세로비율(*y)
     */
    drawStorageTank(scene, t, wRatio, hRatio) {
      console.debug("__________________draw-storage-tank___________________\n", t.name, t.tid);
      const pos = t.position;
      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;

      const tankWidth = t.tankWidth * wRatio;
      const tankHeight = t.tankHeight * hRatio;
      const tankY = (tankHeight / 2) + py;
      const color = getHexColor(t.color);

      // 탱크 그리기
      const tankMatParam = {
        color: color,
        side: THREE.DoubleSide,
        metalness: 0.5,
        roughness: 1,
        clearcoat: 0.5,
        clearcoatRoughness: 0,
        reflectivity: 1
      }

      // const mat2 = new THREE.MeshPhongMaterial({color: getHexColor('#f0f0f0'), opacity: 1, transparent: false} );
      const tankOpenAngle = px < 0 ? getR(0) : getR(180)
      const tankAngleLen = getR(220);

      const cylinderGeo = new THREE.CylinderGeometry(
        tankWidth + 1, tankWidth + 1, tankHeight,
        360, 10, true,
        tankOpenAngle, tankAngleLen
      );

      // const cylinderMat = new THREE.MeshPhysicalMaterial( tankMatParam );
      // const cylinderMesh = new THREE.Mesh(cylinderGeo, cylinderMat);
      // cylinderMesh.position.set(px, tankY, pz); // Position to the calc lat, lng
      // cylinderMesh.updateMatrix();

      // scene.add(cylinderMesh);


      if (!t.machine) {
        console.debug('Machine not found ---', t.tid, t.name);
        return;
      }

      // 6각형 선서 그리기
      const sensHeight = t.machine.sensorLen * hRatio;
      const sensY = sensHeight + py;
      const pipeY = sensHeight / 2 + py;

      console.debug('___________sensor length, sensor y ____________', t.machine.sensorLen, sensHeight)
      const sensorGeo = new THREE.CylinderGeometry(3, 3, 10.0, 6);
      const sensorMat = new THREE.MeshPhongMaterial({
          color: getHexColor('#e0e0e0'),
          opacity: 1,
          transparent: false
        }
      );

      const sensorMesh = new THREE.Mesh(sensorGeo, sensorMat);
      sensorMesh.updateMatrix();


      // 센서파이프 그리기
      const noz = t.nozSize ? t.nozSize : '10';
      let nozSize = Number(noz.replace(/[^0-9]/g, "")) / 10;

      console.debug('___________noz, nozSize ____________', noz, nozSize)
      const pipeGeo = new THREE.CylinderGeometry(nozSize ? nozSize : 10, nozSize ? nozSize : 10, sensHeight, 36);
      const pipeMat = new THREE.MeshPhongMaterial({
        color: getHexColor('#f0f0f0'),
        opacity: 1,
        transparent: false
      });

      const pipeMesh = new THREE.Mesh(pipeGeo, pipeMat);
      pipeMesh.updateMatrix();

      // 센서 및 파이프 위치
      sensorMesh.position.set(px, sensY, pz);
      pipeMesh.position.set(px, pipeY, pz); // 파이프 Y는 탱그 중앙 지점에서 위아래로

      scene.add(sensorMesh);
      scene.add(pipeMesh);


      const tankR = tankWidth + 1;

      let tankMes = null;
      let tankGeo = null
      const tankMat = new THREE.MeshPhysicalMaterial(tankMatParam);

      if (t.tankShape === '1') { // 1: Exclusive
        const roofGeo = new THREE.CylinderGeometry(tankR, tankR, 2, 36, 5, false, tankOpenAngle, tankAngleLen);
        const bottomGeo = new THREE.CylinderGeometry(tankR, nozSize, 2, 36, 5, false);

        tankGeo = mergeBufferGeometries(
          [cylinderGeo,  roofGeo, bottomGeo, /* sensorGeo, pipeGeo */ ],
          [
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(0, tankHeight/2, 0), // roofGeo
            new THREE.Vector3(0, -tankHeight/2, 0), // bottomGeo
            // new THREE.Vector3(0, sensHeight/2, 0), // sensorGeo
            // new THREE.Vector3(0, -sensHeight/2, 0) // pipeGeo
          ],
        );

        tankMes = new THREE.Mesh( tankGeo, tankMat );

      } else if (t.tankShape === '3') { // 3: Cone Roof

        const roofGeo = new THREE.CylinderGeometry(nozSize + 1.5, tankR + 1.8, 2, 18, 5, false, tankOpenAngle, tankAngleLen);
        const roofDecoGeo = new THREE.CylinderGeometry(tankR + 1.7, tankR + 0.7, 10, 18, 3, true);
        const bottomGeo = new THREE.CylinderGeometry(tankR + 0.5, tankR + 1.5, 0.5, 36, 5, false);


        tankGeo = mergeBufferGeometries(
          [cylinderGeo,  roofGeo, roofDecoGeo, bottomGeo, /* sensorGeo, pipeGeo */],
          [
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(0, tankHeight/2, 0),
            new THREE.Vector3(0, tankHeight/2+1, 0),
            new THREE.Vector3(0, -tankHeight/2, 0),
            // new THREE.Vector3(0, sensHeight/2, 0), // sensorGeo
            // new THREE.Vector3(0, -sensHeight/2, 0) // pipeGeo
          ],
        );

        tankMes = new THREE.Mesh( tankGeo, tankMat );
        tankMes.name = 'tank_'+t.tid;

      }

      if(!tankMes) return;

      tankMes.position.set(px, tankY, pz); // Position to the calc lat, lng
      tankMes.updateMatrix();
      scene.add(tankMes);

      this.tankObjs[t.tid] = {mes: tankMes, mat: tankMat };

    },

    /**
     * 드레인 탱크 그리기
     * @param scene
     * @param t
     */
    drawCylinderTank(scene, t) {
      const pos = t.position;
      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;

      console.warn("_____ draw-cylinder-tank _____ ", t.name, "_______");
      const width = t.tankWidth * this.hRatio; // 일부러 크게 적용함
      const color = t.color;
      const tankR = (t.tankHeight * 0.5) * this.hRatio;

      const tankY = tankR + py;

      const cylinderGeometry = new THREE.CylinderGeometry(
        tankR+1,
        tankR+1,
        width+1,
        36); // (radiusTop, radiusBottom, height, radialSegments)

      const cylinderMaterial = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.8,
        wireframe: true
      });

      const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);

      cylinderGeometry.rotateZ(Math.PI / 2);
      cylinderGeometry.rotateY(Math.PI / 4.7);

      cylinder.position.set(px, tankY, pz); // Position the cylinder
      cylinder.updateMatrix();
      cylinder.name = 'tank_'+t.tid;

      // this.scene.add(cylinder);
      /*
            const cylinderOutline = new THREE.Mesh(
              cylinderGeometry,
              new THREE.MeshBasicMaterial({ color: color, wireframe: true })
            );

            cylinderOutline.position.copy(cylinder.position);
            cylinderOutline.rotation.copy(cylinder.rotation);
            */

      scene.add(cylinder);

      this.tankObjs[t.tid] = {mes: cylinder, mat: cylinderMaterial};
    },


    /**
     * 탱크로리 추적 - 작업중
     * @param tid
     * @param coordinates
     */
    moveTankLorry(tid, coordinates) {

      const t = this.tankMap[tid];


      console.debug('moveTankLorry() --->', t.tid, coordinates);

      // const target = this.coordinatesToPosition( coordinates, 24 ); // 경도위도 좌표 변환
      t.location.coordinates = coordinates;
      this.tankMap[tid] = t;

      const target = this.convertTankPosition(tid); // 경도위도 좌표 변환

      console.debug(`moveTankLorry() --- target position ---> `, JSON.stringify(target));

      const object = this.tankObjs[tid].mes;

      /*
      const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });
      timeline.to(object.position, {
        x: target.x,
        y: target.y,
        z: target.z,
        duration: 2,
        ease: "power1.inOut",
        onUpdate: () => {
          this.moveCamToTank(tid);
        }
      });
      */
      this.moveCamToTank(tid);

      const direction = new THREE.Vector3().subVectors(target, object.position);
      const angle = Math.atan2(direction.x, direction.z);
      gsap.to(object.rotation, {y: angle, duration: 1, ease: "power1.inOut"});

      gsap.to(object.position, {
        x: target.x, // 좌측
        y: target.y, // 높이
        z: target.z, // 아래
        duration: 5, // 애니메이션 시간 (초)
        onUpdate: () => {
          this.moveCamToTank(tid);
        }
      });
      // const direction = new THREE.Vector3().subVectors(target, object.position).normalize();
      // // const distance = object.position.distanceTo(target);
      // object.position.add(direction.multiplyScalar(speed));
    },

    /**
     * 라벨출력
     * @param text 라벨
     * @param pos 위치
     * @param color 컬러
     * @param size 크기
     * @param height 두께
     */
    drawLabel(text, pos, color = 0xFF0000, size = 30, height = 10, tid = null) {
      let thr = 0;
      if (tid) thr = this.tankMap[tid].tankHeight * this.hRatio + pos.ay
      else thr = pos.y + pos.ay;

      let px = pos.x + pos.ax;
      let py = pos.ly ? pos.ly : thr + 10
      let pz = pos.z + pos.az;


      const geometry = new TextGeometry(text, {
        font: this.font,
        size: size,
        depth: height
      });


      const material = new THREE.MeshBasicMaterial({color: color});
      const label = new THREE.Mesh(geometry, material);

      if (pos.lyd) geometry.rotateY(pos.lyd);
      if( pos.z < 0 ) geometry.rotateY(Math.PI);
      // geometry.rotateY(Math.PI / 4.7);
      // geometry.rotateX(Math.PI / -4);

      label.position.set(px, py, pz);

      if(tid) label.name = 'label_'+tid;

      this.scene.add(label);
    },

    onMouseClick(event) {
      // 마우스 좌표를 정규화된 장치 좌표(-1 to +1)로 변환합니다.
      // this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      // this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      //
      let gapX = event.clientX - event.offsetX;
      let gapY = event.clientY - event.offsetY;

      this.mouse.x = ((event.clientX - gapX)/( this.container.clientWidth )) * 2 - 1;
      this.mouse.y = -((event.clientY - gapY)/( this.container.clientHeight )) * 2 + 1;

      // this.mouse.x = (event.clientX / this.container.offsetWidth) * 2 - 1;
      // this.mouse.y = -(event.clientY / this.container.offsetHeight) * 2 + 1;
      // 사용하여 클릭된 위치의 객체 감지
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.scene.children);

      // 감지된 객체가 있는 경우 색상을 변경
      if (intersects.length > 0) {
        const obj = intersects[0].object;
        if( !obj.name ) return;

        const tid = obj.name.slice(-4);
        this.closeLayer();
        if(!this.tank) this.popupLayer( tid );

        // console.warn(  'obj =',obj );
        // console.warn(  'obj.name =',obj.name );
        // console.warn(  'obj.material =',obj.material );
        // obj.material.color?.set(0xff0000);
      }
    },


    onDocumentMouseClick(event) {

      event.preventDefault();

      const mouse = new THREE.Vector2();
      const raycaster = new THREE.Raycaster();

      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, this.camera);

      const intersects = raycaster.intersectObjects(this.scene.children, true);
      if (intersects.length > 0) {
        this.screenPoint = intersects[0].point;
        // console.log('Clicked position ===> ', point);
      }

    },

    onPageResizeHandler() {
      // console.debug( 'on -- pageResizeHandler --- resized---', window.innerWidth, window.innerHeight );
      this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    },

    convertTankPosition(tid, zoom = 25) {
      const t = this.tankMap[tid];

      const lat = t.location.coordinates[1];
      const lng = t.location.coordinates[0];


      if (!lat || !lng) {
        t.position = {x: 0, y: 0, z: 0, ax: 0, ay: 0, az: 0}
        return t.position
      }

      let centerPoint = Math.pow(2, zoom);
      let totalPixels = centerPoint * 2;
      let pixelsPerLngDegree = totalPixels / 360;
      let pixelsPerLngRadian = totalPixels / (2 * Math.PI);

      let sinY = Math.min(Math.max(Math.sin(lat * (Math.PI / 180)), -0.9999), 0.9999);

      let x = Math.round(centerPoint + lng * pixelsPerLngDegree);
      let z = Math.round(centerPoint - 0.5 * Math.log((1 + sinY) / (1 - sinY)) * pixelsPerLngRadian);


      let adjX = 57322397;
      let adjZ = 26186953;

      this.tankMap[tid].position.x = x - adjX;
      this.tankMap[tid].position.z = z - adjZ;


      // console.debug( 'convertTankPosition() coordinates --->', t.location.coordinates );
      // console.debug( 'convertTankPosition() --- calc ----- X, Z => ', x, z );
      // console.debug( `convertTankPosition() --- adjust ( ${x} - ${adjX} ,  ${z} - ${adjZ}) ====> ` , pos.x, pos.z );
      // console.debug( 'convertTankPosition() --- tankMap position --->', JSON.stringify(this.tankMap[tid].position) );
      // // console.debug( 'origin ----- X, Z => ', t?.position?.x, t?.position?.z );
      // console.debug( `convertTankPosition() ---> ${t.name} (${t.tid}) X,Z => ( ${x}, ${z} ) ==> ${JSON.stringify(pos)}`);

      return this.tankMap[tid].position;
    },

    convertPositionByPacket(pk, zoom = 25) {
      const {tid} = pk;
      this.tankMap[tid].location = pk.location;

      if( !pk.location ) return {x: 0, y: 0, z: 0, ax: 0, ay: 0, az: 0}

      const t = this.tankMap[tid];
      const lat = t.location?.coordinates[1];
      const lng = t.location?.coordinates[0];

      if (!lat || !lng) {
        t.position = {x: 0, y: 0, z: 0, ax: 0, ay: 0, az: 0}
        return t.position
      }

      let centerPoint = Math.pow(2, zoom);
      let totalPixels = centerPoint * 2;
      let pixelsPerLngDegree = totalPixels / 360;
      let pixelsPerLngRadian = totalPixels / (2 * Math.PI);

      let sinY = Math.min(Math.max(Math.sin(lat * (Math.PI / 180)), -0.9999), 0.9999);

      let x = Math.round(centerPoint + lng * pixelsPerLngDegree);
      let z = Math.round(centerPoint - 0.5 * Math.log((1 + sinY) / (1 - sinY)) * pixelsPerLngRadian);

      let adjX = 57322397;
      let adjZ = 26186953;

      t.position.x = x - adjX;
      t.position.z = z - adjZ;

      // console.debug( 'convertTankPosition() coordinates --->', t.location.coordinates );
      // console.debug( 'convertTankPosition() --- calc ----- X, Z => ', x, z );
      // console.debug( `convertTankPosition() --- adjust ( ${x} - ${adjX} ,  ${z} - ${adjZ}) ====> ` , pos.x, pos.z );
      // console.debug( 'convertTankPosition() --- tankMap position --->', JSON.stringify(this.tankMap[tid].position) );
      // // console.debug( 'origin ----- X, Z => ', t?.position?.x, t?.position?.z );
      // console.debug( `convertTankPosition() ---> ${t.name} (${t.tid}) X,Z => ( ${x}, ${z} ) ==> ${JSON.stringify(pos)}`);

      return t.position;
    },

    setSocketConnection() {
      if (!this.$store.state.socket) {
        console.error("[MapMonitor] ############ socket not exists...", this.$store.state.socket);
        // console.warn("[MONITOR] ############ waiting register handler...");
      } else {
        console.warn("[MapMonitor] ############ socket object ----->", this.$store.state.socket);
        // console.warn("[MONITOR] ############ waiting register handler...");
      }

      setTimeout(() => {
        console.warn("[MapMonitor] ############ register atgDataHandler #########");
        this.socket = this.$store.state.socket;
        if (this.socket) {
          this.socket.removeListener('packet', this.atgDataMapHandler)
          this.socket.removeListener('disconnect');
          this.socket.removeListener('connect');
          this.socket.on('packet', this.atgDataMapHandler);
          this.socket.on('disconnect', () => {
            speech('ATG 서버와 통신이 유실 되었습니다.');
            modalWarn(this.$bvModal, 'ATG 서버와 통신이 유실 되었습니다.');
            // alert("[WARN] ATG 서버와 통신이 유실되었습니다. 모니터링을 종료합니다.")
            // window.close();
          });
          this.socket.on('connect', () => {
            speech('ATG 서버와 통신이 연결 되었습니다.');
            modalSuccess(this.$bvModal, 'ATG 서버와 통신이 연결 되었습니다.');
          });
        }
        console.warn("[MapMonitor] ############ socket object ------> is connecting? ", this.socket.connected);
      }, 3000);

    },

    atgDataMapHandler(data) {
      console.info('-----atgDataMapHandler-----\n', data);
      const pk = data.payload;
      const {fcd} = pk;
      if( Number(fcd) > 1 ){
        return false;
      }else{
        this.handleDrawVolume(pk);
      }
    },

    startDrag(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX - this.layerLeft;
      this.dragStartY = event.clientY - this.layerTop;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },

    onDrag(event) {
      if (this.isDragging) {
        const parentRect = this.$refs.mapContainer.getBoundingClientRect();
        const newTop = event.clientY - this.dragStartY;
        const newLeft = event.clientX - this.dragStartX;

        // 상위 div 안에 있도록 위치를 제한합니다.
        this.layerLeft = Math.max(0, Math.min(newLeft, parentRect.width - 500));
        this.layerTop = Math.max(0, Math.min(newTop, parentRect.height - 660));
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },

  },


  beforeDestroy() {
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    // this.renderer.domElement.removeEventListener('click', this.onDocumentMouseClick);
    window.removeEventListener('resize', this.onPageResizeHandler);
    window.removeEventListener('click', this.onMouseClick, false);

    this.renderer.renderLists.dispose();

    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if (this.socket) {
      this.socket.removeListener('packet', this.atgDataMapHandler);
      this.socket.removeListener('disconnect');
      this.socket.removeListener('connect');
      // this.socket.off('atg-data', this.atgDataHandler );
    }
  },
}
</script>
